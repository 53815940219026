.nav-link{
    color: var(--blue2)!important;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show, .nav-link:hover{
    color:var(--blue3) !important;
    font-weight: bold;
}
.companyName{
position: absolute;  
top: 20px;  
font-family:Georgia, 'Times New Roman', Times, serif;
background: -webkit-linear-gradient(var(--blue3),var(--blue2));
-webkit-background-clip: text;
-webkit-text-fill-color:transparent;
font-weight: bold;
}
.logo{
    width: 90px;
}
@media (min-width:991px){
.logo{
    transform: translateY(8%) !important;
}
.nav-link::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: var(--blue3);
    visibility: hidden;
    transition: 0.3s;
}
.nav-link:hover::before, .nav-link:active::before{
    width: 100%;
    visibility: visible;
}
}
@media (min-width:260px){
    .logo{
        transform: translateY(-20%);
}
    .navbar-toggler{
        transform: translateY(-65%);
    }
}
.navbar{
    background-color: var(--blue0);
    height: 70px;
    margin:0 20px;
    border-radius: 16px;
    padding: 0.5rem;
    box-shadow: 3px 3px 4px var(--blue2);
}

.header{
    background-image: url('../../../public/images/bg2.webp');
    background-size: cover;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-position: center;
}
.header::before{
    content: "";
    background-color: rgba(0, 0, 0, 0.58);
    position: absolute;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.navbar-toggler{
    border: none;
    font-size: 1.25rem;
}
.navbar-toggler:focus, .btn-close:focus{
    outline: none;
    box-shadow: none;
}
.header .container{
    position: relative;
    height: 100vh;
    z-index: 1;
    color: var(--blue0);
}
.btn1, .contact, .about{
    background: rgb(15,16,53);
    background: linear-gradient(90deg, rgba(15,16,53,1) 0%, rgba(54,84,134,1) 50%);
    color: var(--blue0);
    border: 3px solid var(--blue2);
}
.btn1:hover, .contact:hover , .about:hover{
    background-color: var(--blue1) !important;
    color: var(--blue3);
    font-weight: bold;
    border: none;
    transition: 0.3s all;
    border: 3px solid var(--blue3);
}
.welcomeContainer{
    border-bottom: 2px solid rgb(0, 1, 24);
    border-top: 2px solid rgba(54,84,134,1);
    border-radius: 20px;
}
.welcome{
    padding: 10px;
    justify-content: center;
    font-weight: 500;
    margin: auto;
    font-size: 1.25rem;
    color: var(--blue2);
}
.welcome-p, .about-p{
    color: var(--blue3);
}
.home-about{
    border: 2px solid var(--blue1);
    border-radius: 5px;
}
.home-about-translat{
    margin-top: 80px;
}